import $ from 'jquery';
import SmoothScroll from "smooth-scroll";

window.addEventListener('DOMContentLoaded', () => {

	(function(){

		var doingAjax = false,
			nextPage = 3,
			postsContainer = $('.news-container');

		$('a.load-more').on('click', function(e){

			e.preventDefault();

			var button = $(this),
				ajaxType = button.attr('data-type'),
				nextPage = button.attr('data-page'),
				termID = button.attr('data-id'),
				searchKey = button.attr('data-searchkey');

			if (!doingAjax) {

				button.addClass('loading');
				doingAjax = true;

				$.ajax({
					type: 'POST',
					url: jsData.ajaxUrl,
					data: {
						'action': 'get_posts_by_type',
						'type': ajaxType,
						'page': nextPage,
						'termID': termID,
						'searchKey': searchKey
					},
					success: function(response){

						response = JSON.parse(response);
						postsContainer.append(response.content);

						let anchor = document.getElementById(response.anchorID);
						let scroll = new SmoothScroll();
						scroll.animateScroll(anchor, null, { header: '#masthead', offset: 30 });

						if (response.pages_left == 0) button.slideUp();
						button.attr('data-page', parseInt(nextPage) + 1);
						doingAjax = false;
						button.removeClass('loading');

					}
				});
			}
		})
	})();

});