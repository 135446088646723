// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  // home: $(".home-slider .inner"),
  testimonials: $(".testimonials-slider .inner"),
  trainers: $(".trainers-slider .inner"),
  team: $(".team-slider .inner"),
  images: $(".images-slider .inner"),
  recommendations: $(".they-recommend-us .inner")
};

sliders.recommendations.slick({
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  nextArrow: '<button type="button" class="slick-next">></button>',
  prevArrow: '<button type="button" class="slick-prev"><</button>',
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

// sliders.home.slick({
//   dots: false,
//   arrows: false,
//   autoplay: true,
//   infinite: true,
//   vertical: true,
//   prevArrow: $(".home-slider-prev"),
//   nextArrow: $(".home-slider-next"),
//   customPaging: (slider, i) => {
//     let dot;
//     let text = $(slider.$slides[i])
//       .find(".single-slide")
//       .data("nav-text");
//     let link = $(slider.$slides[i])
//       .find("a")
//       .attr("href");

//     if (link) {
//       dot = document.createElement("a");
//       dot.href = link;
//     } else {
//       dot = document.createElement("div");
//     }

//     dot.classList.add("dot");
//     dot.innerText = text;

//     return dot;
//   },
//   appendDots: $(".home-slider-dots")
// });

sliders.testimonials.slick({
  dots: false,
  arrows: true,
  autoplay: true,
  prevArrow: $(".testimonials-slider-prev"),
  nextArrow: $(".testimonials-slider-next")
});

sliders.trainers.slick({
  dots: false,
  arrows: true,
  autoplay: true,
  prevArrow: $(".trainers-slider-prev"),
  nextArrow: $(".trainers-slider-next"),
  slidesToShow: 3,
  pauseOnFocus: true,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.team.slick({
  dots: false,
  arrows: true,
  autoplay: false,
  prevArrow: $(".team-slider-prev"),
  nextArrow: $(".team-slider-next"),
  infinite: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.images.slick({
  dots: false,
  arrows: true,
  autoplay: true,
  prevArrow: $(".logo-slider-prev"),
  nextArrow: $(".logo-slider-next"),
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    }
  ]
});
