import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import $ from "jquery";
import HomeSlider from "./homeSlider";
//import "babel-polyfill";

window.addEventListener("load", () => {
  const siteHeader = new SiteHeader();
  siteHeader.stickyHeader();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

  let homeSliderContainer = document.querySelector(".home-slider .inner");
  if (homeSliderContainer) {
    new HomeSlider(homeSliderContainer);
  }

  let offerTiles = document.querySelectorAll(".single-offer.open-popup");

  if (offerTiles.length) {
    const popupOverlay = document.querySelector(".popup-overlay.offers");
    offerTiles.forEach(tile => {
      tile.querySelector("a").addEventListener("click", e => {
        e.preventDefault();
        let popupContent = tile.querySelector(".popup");
        popupOverlay.classList.add("open");
        popupContent.classList.add("open");
      });
    });

    popupOverlay.addEventListener("click", function() {
      let activePopup = document.querySelector(".popup.open");

      this.classList.remove("open");
      activePopup.classList.remove("open");
    });
  }

  const requestSelector = document.querySelector('select[name="request-type"]');

  if (requestSelector) {
    requestSelector.addEventListener("change", function(e) {
      $(".request-form-container .popup-form.active")
        .slideUp("fast")
        .removeClass("active");

      if (this.value) {
        if (this.value == "employee") {
          window.location =
            "https://ats.hrlink.pl/?sv=4_x_x_x_x_x_x&page_typ=4&module=aplikacja&page=kandydat&typ=dodajnew&fix=2158&id_ogloszenie=5020&id_reg=3080&szata=25&relurl=102&reg=";
        } else {
          $(`#form-${this.value}`)
            .slideDown("fast")
            .addClass("active");
        }
      }
    });
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
