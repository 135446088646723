//js
require("./js/popup");
require("./js/ajax-posts");
require("./js/slider");
require("./js/scroll");
require("./js/vendor/jquery.scrollto");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/filters");
require("./js/forms/registration-form");
require("./js/partners");
require("./js/trainers");
require("./js/main");

//fonts
// require('./fonts/font/stylesheet.css');

// css
import normalizecss from "normalize.css";
require("./sass/style.scss");
