import $ from 'jquery';

window.addEventListener('DOMContentLoaded', () => {

	(function(){

		var doingAjax = false,
			popupContainer = $('.trainer-popup-content'),
			popupOverlay = $('.popup-overlay.trainers'),
			popupContent = popupContainer.find('.content');

		$('.single-trainer').on('click', function(e){

			e.preventDefault();

			var trainerElement = $(this),
				trainerID = trainerElement.attr('data-id');

			if (!doingAjax) {

				popupContent.empty();
				popupContainer.addClass('loading open');
				popupOverlay.addClass('open');
				doingAjax = true;

				$.ajax({
					type: 'POST',
					url: jsData.ajaxUrl,
					data: {
						'action': 'get_trainer_data',
						'trainerID': trainerID
					},
					success: function(response){
						response = JSON.parse(response);

						doingAjax = false;
						popupContent.html(response.content);
						popupContainer.removeClass('loading');
					}
				});
			}
		})

		$('.popup-overlay.trainers, .trainer-popup-content .close').on("click", function() {
			popupOverlay.removeClass('open');
			popupContainer.removeClass('open');
		});
	})();

});