import $ from "jquery";

window.addEventListener('DOMContentLoaded', () => {

    let registrationForm = document.getElementById('register-form');
    let doingAjax = false;

    if (registrationForm) {

        $('.toggle-optional').on('change', function(){
            let $this = $(this);
            let values = $this.val();
            let optionalField = $this.closest('.form-field').next('.form-field.optional');
            const isValue = this.type == 'select-multiple' ? values.includes('inne') : values == 'inne';

            if (isValue) {
                optionalField.slideDown();
                if (this.type == 'checkbox' && !this.checked) {
                    optionalField.slideUp();
                }
            } else {
                optionalField.slideUp();
            }
        });



        registrationForm.addEventListener('submit', e => { 

            e.preventDefault();

            let submitButton = registrationForm.querySelector('input[type="submit"]');
            let formData = new FormData(registrationForm);
            formData.append('action', 'process_registration_form');

            if (!doingAjax) {

                doingAjax = true;
                submitButton.classList.add('loading');

                $('p.error').remove();

                $.ajax({
                    method: 'POST',
                    url: jsData.ajaxUrl,
                    data: formData,
                    type: 'POST',
                    processData: false,
                    contentType: false,
                    success: response => {
                        const data = JSON.parse(response);

                        doingAjax = false;
                        submitButton.classList.remove('loading');

                        if (!data.errors) {
                            $('#register-inner').slideUp('fast', function(){
                                let successBox = $('.success-box');

                                successBox.find('.login').html(data.userFields.user_login);
                                successBox.find('.password').html(data.userFields.user_pass);
                                successBox.fadeIn('fast');
                            });
                        } else {
                            $('#register-inner').append('<p class="error">Wystąpił bląd. Prawdopodobnie taki użytkownik już istnieje, lub podany adres email jest już używany.</p>');
                        }
                    }
                });
            }

        });
    }

});