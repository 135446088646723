import { getCoords }  from "./helper";

export default class SiteHeader {
  constructor() {
    this.siteHeader = document.getElementById("masthead");
  }

  setBodyPaddingTop() {
    let $body = document.querySelector("body"),
      siteHeaderHeight = this.siteHeader.clientHeight;

    $body.style.paddingTop = `${siteHeaderHeight}px`;
  }

  stickyHeader() {
    window.addEventListener("scroll", () => {
      let headerOffsetTop = getCoords(this.siteHeader).top;

      if (headerOffsetTop > 0) this.siteHeader.classList.add("sticky");
      else this.siteHeader.classList.remove("sticky");
    });
  }
}
