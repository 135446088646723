const popup = document.getElementById("popup");
const background = document.getElementById("background");
const content = document.getElementById("content");
const contentButton = document.getElementsByClassName("contentButton");

if (contentButton) {
  for (let i = 0; i < contentButton.length; i++) {
    contentButton[i].onclick = () => {
      visible();
      console.log(contentButton[i]);
      const h2 = document.createElement("h2");
      const p = document.createElement("p");
      content.innerHTML = "";
      content.appendChild(h2);
      content.appendChild(p);
      h2.innerHTML = contentButton[i].getAttribute("data-title");
      p.innerHTML = contentButton[i].getAttribute("data-description");
    };
  }
}

if (background) {
  background.onclick = () => {
    visible();
  };
}

function visible() {
  const childs = popup.childNodes;
  if (popup.classList.contains("contact__invisible")) {
    popup.classList.remove("contact__invisible");
    for (let i = 0; i <= childs.length; i++) {
      if (isElement(childs[i])) {
        childs[i].classList.remove("contact__invisible");
      }
    }
  } else {
    popup.classList.add("contact__invisible");
    for (let i = 0; i <= childs.length; i++) {
      if (isElement(childs[i])) {
        childs[i].classList.add("contact__invisible");
      }
    }
  }
}

function isElement(o) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement
    : o &&
        typeof o === "object" &&
        true &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}
