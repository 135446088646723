//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";

const smoothScroll = new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container], .load-more, .header-actions a",
  header: '#masthead'
});

window.addEventListener('DOMContentLoaded', () => {

    if(window.location.hash) {
        let hash = window.location.hash.substring(1);
        let item = document.getElementById(hash);

        if (item) {
            smoothScroll.animateScroll(item);
        }       
    }

});