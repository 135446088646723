function filterData(filtersForm, filters) {
  let tiles = document.querySelectorAll(".filter-tile");
  let noResultsMessage = document.getElementById("fliters-empty");
  let foundResults = false;

  for (let i = 0; i < tiles.length; i++) {
    let tile = tiles[i];
    let willTileFilter = 0;

    filters.forEach(input => {
      let filter = input.value;
      let filterType = input.id;
      let searchString = tile.getAttribute(`data-${filterType}`);

      if (input.tagName == "INPUT" && input.type == "text") {
        willTileFilter +=
          searchString.indexOf(filter.toLowerCase()) > -1 ? 1 : 0;
      } else {
        if (filter) {
          willTileFilter += searchString == filter ? 1 : 0;
        } else {
          willTileFilter++;
        }
      }
    });

    if (willTileFilter == filters.length) {
      tile.style.display = "flex";
      foundResults = true;
    } else {
      tile.style.display = "none";
    }
  }

  noResultsMessage.style.display = foundResults ? "none" : "block";
}

window.addEventListener("DOMContentLoaded", () => {
  let filtersForm = document.getElementById("filters-form");

  if (filtersForm) {
    let filterInputs = filtersForm.querySelectorAll(
      'select, input[type="text"]'
    );
    filterInputs.forEach(input => {
      if (input.tagName == "SELECT") {
        input.addEventListener("change", () =>
          filterData(filtersForm, filterInputs)
        );
      } else {
        input.addEventListener("input", () =>
          filterData(filtersForm, filterInputs)
        );
      }
    });
  }
});
