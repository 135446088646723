import $ from "jquery";

function showPartnerInfo(partnerInfo) {
  const infoTemplate = document.getElementById("partner-info");
  const templateHTML = $(infoTemplate.innerHTML)[0];
  const popupOverlay = document.querySelector(".popup-overlay.partners");
  const popupContent = document.querySelector(".popup.partners");

  Object.keys(partnerInfo).forEach(key => {
    const infoElem = templateHTML.getElementsByClassName(key)[0];

    infoElem.innerHTML = partnerInfo[key];
    popupOverlay.classList.add("open");
    popupContent.classList.add("open");
  });

  $(popupContent).html(templateHTML);

  popupOverlay.addEventListener("click", function() {
    let activePopup = document.querySelector(".popup.open");

    this.classList.remove("open");
    activePopup.classList.remove("open");
  });
}

window.addEventListener("load", () => {
  if (window.hasOwnProperty("partnersData")) {
    const partnersTable = document.querySelector(".partners-table");
    const rows = partnersTable.querySelectorAll(".single-partner");

    rows.forEach(row => {
      row.addEventListener("click", function(e) {
        e.preventDefault();
        const index = this.getAttribute("data-index");
        const partnerInfo = partnersData[index];

        showPartnerInfo(partnerInfo);
      });
    });
  }
});
