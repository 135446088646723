export default class HomeSlider {
  constructor(container) {
    this.container = container;
    this.slides = container.querySelectorAll("h2");
    this.slidesCount = this.slides.length - 1;
    this.currentSlide = 0;
    this.init();
  }

  init() {
    this.slides[0].classList.add("is-visible");
    this.changeSlides();
  }

  changeSlides() {
    let nextSlide = 1;
    const change = () => {
      this.slides.forEach(slide => {
        slide.classList.remove("is-visible");
        slide.classList.remove("is-hidden");
      });

      this.slides[this.currentSlide].classList.add("is-hidden");

      if (this.currentSlide < this.slidesCount) {
        nextSlide = this.currentSlide + 1;
        this.currentSlide++;
      } else if (this.currentSlide == this.slidesCount) {
        nextSlide = 0;
        this.currentSlide = 0;
      }

      this.slides[nextSlide].classList.add("is-visible");
    };
    const interval = setInterval(change, 3500);
  }
}
